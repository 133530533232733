import {useEffect} from "react";
import Header from "../../navigation/header/header";
import "./home.scss";
import MainImage from "../../../assets/images/home/home-main-image.png";
import Typewriter from "typewriter-effect";
import Footer from "../../navigation/footer/footer";
import axios from "axios";


function Home() {

    function getTypewriter() {
        const typewriterStrings = [
            "Software Engineers",
            "Marketing Pros",
            "Content Creators",
            "Freelancers",
            "Web Developers",
        ];

        return (
            <Typewriter
                options={{
                    strings: typewriterStrings,
                    autoStart: true,
                    loop: true,
                    deleteSpeed: 50,
                    delay: 100,
                }}
            />
        );
    }

    useEffect(() => {
        axios.get(`https://jsonplaceholder.typicode.com/users`).then((res) => {
        });
    });

    return (
        <div>
            <div className={"home_container"}>
                <Header/>
                <div className={"home_main_banner"}>
                    <div className={"home_main_tagline"}>
                        <h1>Turn Your Resume Into a Website in Minutes</h1>
                        <h3>A free, self-hosted, and open-source website builder</h3>
                    </div>
                    <p className={"home-typewriter-text"}>Seriously free websites for:</p>
                    <div className={"home-typewriter-text"}>
                        <span>{getTypewriter()}</span>
                    </div>

                    <div className={"home_banner_image"}>
                        <img src={MainImage}/>
                    </div>
                </div>

                <div className={"home_main_subheading"}>
                    <h1>The fastest way to turn your resume into a website</h1>
                    <p>Stand out. Get noticed. Get hired.</p>
                    <a href={"https://github.com/ResumeCharge/standalone"} target={"_blank"}
                       className={"action-button-get-started"}>Get Started</a>
                    <div className={"deployment_instructions"}>
                        <p>
                            The easiest way to get started using ResumeCharge is to clone this repo and execute the
                            compose.yml script.
                        </p>
                        <ol>
                            <li>Clone the repo</li>
                            <p><code>git clone https://github.com/ResumeCharge/standalone.git</code></p>
                            <li>Set a private key in compose.yml</li>
                            <p><code>Replace {"<MY_SECRET_KEY>"} with your secret key</code></p>
                            <li>Run the app</li>
                            <p><code>docker compose up</code></p>
                            <li>Navigate to localhost:3000 in your browser</li>
                            <p><code>http://localhost:3000</code></p>
                        </ol>
                    </div>

                </div>
                <div className={"home_features_section"}>
                    <h1>Features You Want</h1>
                    <div className={"home_features_grid"}>
                        <div className={"home_features_item"}>
                            <h1>Easy To Use</h1>
                            <p>
                                Beautiful templates make it easy to deploy a beautiful and
                                modern resume website in minutes
                            </p>
                        </div>
                        <div className={"home_features_item"}>
                            <h1>Blazing Fast</h1>
                            <p>
                                Get up and running in a matter of minutes. Changes take minutes,
                                not hours.
                            </p>
                        </div>
                        <div className={"home_features_item"}>
                            <h1>Power User Support</h1>
                            <p>
                                With the option to deploy to your Github Account, you can tweak,
                                modify, and change to your heart's content
                            </p>
                        </div>
                        <div className={"home_features_item"}>
                            <h1>Personalized Domain</h1>
                            <p>All websites include a free domain for easy sharing.</p>
                        </div>
                        <div className={"home_features_item"}>
                            <h1>Free and Open-Source</h1>
                            <p>
                                Take your website anywhere with complete and total ownership of
                                the source code. No lock-ins or restrictive licenses here.
                            </p>
                        </div>
                        <div className={"home_features_item"}>
                            <h1>Safe, Secure, And Fast</h1>
                            <p>
                                Powered by GitHub, and Jekyll, your website is built, deployed,
                                and hosted using the most trusted technologies on the web.
                            </p>
                            <div className={"home_features_item_multi_image_container"}></div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

export default Home;
