import {
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import QuestionAnswerOutlinedIcon from "@mui/icons-material/QuestionAnswerOutlined";
import MonetizationOnOutlinedIcon from "@mui/icons-material/MonetizationOnOutlined";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import ArticleOutlinedIcon from "@mui/icons-material/ArticleOutlined";

export const getMenu = (
  clickAction: (path: string) => void,
) => {
  return (
    <List className={"sidebar_collapsible_nav"}>
      <ListItem
        key={"about"}
        disablePadding
        onClick={() => {
          clickAction("/about");
        }}
      >
        <ListItemButton>
          <ListItemIcon>
            <InfoOutlinedIcon htmlColor={"black"} />
          </ListItemIcon>
          <ListItemText primary={"About"} />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"pricing"}
        disablePadding
        onClick={() => {
          clickAction("/pricing");
        }}
      >
        <ListItemButton>
          <ListItemIcon>
            <MonetizationOnOutlinedIcon htmlColor={"black"} />
          </ListItemIcon>
          <ListItemText primary={"Pricing"} />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"templates"}
        disablePadding
        onClick={() => {
          clickAction("/templates");
        }}
      >
        <ListItemButton>
          <ListItemIcon>
            <ArticleOutlinedIcon htmlColor={"black"} />
          </ListItemIcon>
          <ListItemText primary={"Templates"} />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"faq"}
        disablePadding
        onClick={() => {
          clickAction("/faq");
        }}
      >
        <ListItemButton>
          <ListItemIcon>
            <QuestionAnswerOutlinedIcon htmlColor={"black"} />
          </ListItemIcon>
          <ListItemText primary={"FAQs"} />
        </ListItemButton>
      </ListItem>
      <ListItem
        key={"support"}
        disablePadding
        onClick={() => {
          clickAction("/contact-us");
        }}
      >
        <ListItemButton>
          <ListItemIcon>
            <HelpOutlineIcon htmlColor={"black"} />
          </ListItemIcon>
          <ListItemText primary={"Help & Support"} />
        </ListItemButton>
      </ListItem>
    </List>
  );
};
