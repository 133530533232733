import React from "react";
import "./App.css";
import "antd/dist/antd.css";
import {BrowserRouter as Router, Route, Routes} from "react-router-dom";
import Home from "./components/pages/home/home";
import About from "./components/pages/about/about";
import FAQ from "./components/pages/FAQ/faq";
import Templates from "./components/pages/templates/templates";
import faqItems from "./components/pages/FAQ/faq-items";

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/about" element={<About/>}/>
                <Route path="/faq" element={<FAQ faqItems={faqItems}/>}/>
                <Route path="/templates" element={<Templates/>}/>
                <Route path="*" element={<Home/>}/>
            </Routes>
        </Router>
    );
}

export default App;
